











import { Component, Vue } from 'vue-property-decorator';

import AppBar from '@/components/structure/AppBar.vue';
import AppNavDrawer from '@/components/structure/AppNavDrawer.vue';

@Component({
  components: {
    AppBar,
    AppNavDrawer,
  },
})
export default class Base extends Vue {}
