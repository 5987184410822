import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Base from '../views/Base.vue';

import store from '@/store';
import UserTypes from '@/store/types/UserTypes';

import RouterController from './controller.router';

Vue.use(VueRouter);

/**
 * Solo exisite una redireccion de Auth<redirectIfAuth> por que todas las url comprobaran si el usuario esta logeado
 */
const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Base,
    children: RouterController,
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import(/* webpackChunkName: "access" */ '../views/Access.vue'),
    meta: { redirectIfAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  const iAdminsLogged: boolean = store.getters[`userAuthStore/${UserTypes.getters.USERISLOGGED}`];
  console.log(iAdminsLogged);
  if (!iAdminsLogged && to.name !== 'Access') return next({ name: 'Access' });
  if (to.matched.some(record => record.meta.redirectIfAuth)) {
    if (iAdminsLogged) return next({ name: 'Dashboard' });
  }
  return next();
});

export default router;
