







































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import WebConfTypes from '@/store/types/WebConfTypes';
@Component
export default class AppNavDrawer extends Vue {
  @Getter(`webConfigStore/${WebConfTypes.getters.GETSHOWNAVDRAWER}`) showNavDrawer!: void;
}
