export default {
  mutations: {
    SETUSER: 'SETUSER',
  },
  getters: {
    GETUSER: 'GETUSER',
    USERISLOGGED: 'USERISLOGGED',
    USERTOKEN: 'USERTOKEN',
  },
  actions: {
    LOGINADMIN: 'LOGINADMIN',
    UPDATEADMINDATA: 'UPDATEADMINDATA',
    LOGOUTADMIN: 'LOGOUTADMIN',
  },
};

export type LoginAdminAction = ({
  admin_email,
  admin_password,
}: {
  admin_email: string;
  admin_password: string;
}) => Promise<any>;
