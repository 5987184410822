






import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserTypes from './store/types/UserTypes';

@Component({
  components: {},
})
export default class App extends Vue {
  @Getter(`userAuthStore/${UserTypes.getters.USERISLOGGED}`) isLoggedUser!: boolean;
  @Action(`userAuthStore/${UserTypes.actions.UPDATEADMINDATA}`) updateAdminData!: any;
  blockScreen = false;
  async created() {
    const isLoggedAdmin = this.isLoggedUser;
    if (isLoggedAdmin) this.updateAdminData();
  }
}
