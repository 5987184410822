











import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  inheritAttrs: false,
  props: ['value'],
})
export default class MaterialSelectCountries extends Vue {
  @Prop({ required: true, default: null, type: String })
  value!: string;

  private Countries = [
    {
      value: null,
      text: 'None',
    },
    {
      value: 'AF',
      text: '(AF) Afghanistan',
    },
    {
      value: 'AX',
      text: '(AX) Åland Islands',
    },
    {
      value: 'AL',
      text: '(AL) Albania',
    },
    {
      value: 'DZ',
      text: '(DZ) Algeria',
    },
    {
      value: 'AS',
      text: '(AS) American Samoa',
    },
    {
      value: 'AD',
      text: '(AD) Andorra',
    },
    {
      value: 'AO',
      text: '(AO) Angola',
    },
    {
      value: 'AI',
      text: '(AI) Anguilla',
    },
    {
      value: 'AQ',
      text: '(AQ) Antarctica',
    },
    {
      value: 'AG',
      text: '(AG) Antigua and Barbuda',
    },
    {
      value: 'AR',
      text: '(AR) Argentina',
    },
    {
      value: 'AM',
      text: '(AM) Armenia',
    },
    {
      value: 'AW',
      text: '(AW) Aruba',
    },
    {
      value: 'AU',
      text: '(AU) Australia',
    },
    {
      value: 'AT',
      text: '(AT) Austria',
    },
    {
      value: 'AZ',
      text: '(AZ) Azerbaijan',
    },
    {
      value: 'BS',
      text: '(BS) Bahamas',
    },
    {
      value: 'BH',
      text: '(BH) Bahrain',
    },
    {
      value: 'BD',
      text: '(BD) Bangladesh',
    },
    {
      value: 'BB',
      text: '(BB) Barbados',
    },
    {
      value: 'BY',
      text: '(BY) Belarus',
    },
    {
      value: 'BE',
      text: '(BE) Belgium',
    },
    {
      value: 'BZ',
      text: '(BZ) Belize',
    },
    {
      value: 'BJ',
      text: '(BJ) Benin',
    },
    {
      value: 'BM',
      text: '(BM) Bermuda',
    },
    {
      value: 'BT',
      text: '(BT) Bhutan',
    },
    {
      value: 'BO',
      text: '(BO) Bolivia, Plurinational State of',
    },
    {
      value: 'BQ',
      text: '(BQ) Bonaire, Sint Eustatius and Saba',
    },
    {
      value: 'BA',
      text: '(BA) Bosnia and Herzegovina',
    },
    {
      value: 'BW',
      text: '(BW) Botswana',
    },
    {
      value: 'BV',
      text: '(BV) Bouvet Island',
    },
    {
      value: 'BR',
      text: '(BR) Brazil',
    },
    {
      value: 'IO',
      text: '(IO) British Indian Ocean Territory',
    },
    {
      value: 'BN',
      text: '(BN) Brunei Darussalam',
    },
    {
      value: 'BG',
      text: '(BG) Bulgaria',
    },
    {
      value: 'BF',
      text: '(BF) Burkina Faso',
    },
    {
      value: 'BI',
      text: '(BI) Burundi',
    },
    {
      value: 'KH',
      text: '(KH) Cambodia',
    },
    {
      value: 'CM',
      text: '(CM) Cameroon',
    },
    {
      value: 'CA',
      text: '(CA) Canada',
    },
    {
      value: 'CV',
      text: '(CV) Cape Verde',
    },
    {
      value: 'KY',
      text: '(KY) Cayman Islands',
    },
    {
      value: 'CF',
      text: '(CF) Central African Republic',
    },
    {
      value: 'TD',
      text: '(TD) Chad',
    },
    {
      value: 'CL',
      text: '(CL) Chile',
    },
    {
      value: 'CN',
      text: '(CN) China',
    },
    {
      value: 'CX',
      text: '(CX) Christmas Island',
    },
    {
      value: 'CC',
      text: '(CC) Cocos (Keeling) Islands',
    },
    {
      value: 'CO',
      text: '(CO) Colombia',
    },
    {
      value: 'KM',
      text: '(KM) Comoros',
    },
    {
      value: 'CG',
      text: '(CG) Congo',
    },
    {
      value: 'CD',
      text: '(CD) Congo, the Democratic Republic of the',
    },
    {
      value: 'CK',
      text: '(CK) Cook Islands',
    },
    {
      value: 'CR',
      text: '(CR) Costa Rica',
    },
    {
      value: 'CI',
      text: "(CI) Côte d'Ivoire",
    },
    {
      value: 'HR',
      text: '(HR) Croatia',
    },
    {
      value: 'CU',
      text: '(CU) Cuba',
    },
    {
      value: 'CW',
      text: '(CW) Curaçao',
    },
    {
      value: 'CY',
      text: '(CY) Cyprus',
    },
    {
      value: 'CZ',
      text: '(CZ) Czech Republic',
    },
    {
      value: 'DK',
      text: '(DK) Denmark',
    },
    {
      value: 'DJ',
      text: '(DJ) Djibouti',
    },
    {
      value: 'DM',
      text: '(DM) Dominica',
    },
    {
      value: 'DO',
      text: '(DO) Dominican Republic',
    },
    {
      value: 'EC',
      text: '(EC) Ecuador',
    },
    {
      value: 'EG',
      text: '(EG) Egypt',
    },
    {
      value: 'SV',
      text: '(SV) El Salvador',
    },
    {
      value: 'GQ',
      text: '(GQ) Equatorial Guinea',
    },
    {
      value: 'ER',
      text: '(ER) Eritrea',
    },
    {
      value: 'EE',
      text: '(EE) Estonia',
    },
    {
      value: 'ET',
      text: '(ET) Ethiopia',
    },
    {
      value: 'FK',
      text: '(FK) Falkland Islands (Malvinas)',
    },
    {
      value: 'FO',
      text: '(FO) Faroe Islands',
    },
    {
      value: 'FJ',
      text: '(FJ) Fiji',
    },
    {
      value: 'FI',
      text: '(FI) Finland',
    },
    {
      value: 'FR',
      text: '(FR) France',
    },
    {
      value: 'GF',
      text: '(GF) French Guiana',
    },
    {
      value: 'PF',
      text: '(PF) French Polynesia',
    },
    {
      value: 'TF',
      text: '(TF) French Southern Territories',
    },
    {
      value: 'GA',
      text: '(GA) Gabon',
    },
    {
      value: 'GM',
      text: '(GM) Gambia',
    },
    {
      value: 'GE',
      text: '(GE) Georgia',
    },
    {
      value: 'DE',
      text: '(DE) Germany',
    },
    {
      value: 'GH',
      text: '(GH) Ghana',
    },
    {
      value: 'GI',
      text: '(GI) Gibraltar',
    },
    {
      value: 'GR',
      text: '(GR) Greece',
    },
    {
      value: 'GL',
      text: '(GL) Greenland',
    },
    {
      value: 'GD',
      text: '(GD) Grenada',
    },
    {
      value: 'GP',
      text: '(GP) Guadeloupe',
    },
    {
      value: 'GU',
      text: '(GU) Guam',
    },
    {
      value: 'GT',
      text: '(GT) Guatemala',
    },
    {
      value: 'GG',
      text: '(GG) Guernsey',
    },
    {
      value: 'GN',
      text: '(GN) Guinea',
    },
    {
      value: 'GW',
      text: '(GW) Guinea-Bissau',
    },
    {
      value: 'GY',
      text: '(GY) Guyana',
    },
    {
      value: 'HT',
      text: '(HT) Haiti',
    },
    {
      value: 'HM',
      text: '(HM) Heard Island and McDonald Islands',
    },
    {
      value: 'VA',
      text: '(VA) Holy See (Vatican City State)',
    },
    {
      value: 'HN',
      text: '(HN) Honduras',
    },
    {
      value: 'HK',
      text: '(HK) Hong Kong',
    },
    {
      value: 'HU',
      text: '(HU) Hungary',
    },
    {
      value: 'IS',
      text: '(IS) Iceland',
    },
    {
      value: 'IN',
      text: '(IN) India',
    },
    {
      value: 'ID',
      text: '(ID) Indonesia',
    },
    {
      value: 'IR',
      text: '(IR) Iran, Islamic Republic of',
    },
    {
      value: 'IQ',
      text: '(IQ) Iraq',
    },
    {
      value: 'IE',
      text: '(IE) Ireland',
    },
    {
      value: 'IM',
      text: '(IM) Isle of Man',
    },
    {
      value: 'IL',
      text: '(IL) Israel',
    },
    {
      value: 'IT',
      text: '(IT) Italy',
    },
    {
      value: 'JM',
      text: '(JM) Jamaica',
    },
    {
      value: 'JP',
      text: '(JP) Japan',
    },
    {
      value: 'JE',
      text: '(JE) Jersey',
    },
    {
      value: 'JO',
      text: '(JO) Jordan',
    },
    {
      value: 'KZ',
      text: '(KZ) Kazakhstan',
    },
    {
      value: 'KE',
      text: '(KE) Kenya',
    },
    {
      value: 'KI',
      text: '(KI) Kiribati',
    },
    {
      value: 'KP',
      text: "(KP) Korea, Democratic People's Republic of",
    },
    {
      value: 'KR',
      text: '(KR) Korea, Republic of',
    },
    {
      value: 'KW',
      text: '(KW) Kuwait',
    },
    {
      value: 'KG',
      text: '(KG) Kyrgyzstan',
    },
    {
      value: 'LA',
      text: "(LA) Lao People's Democratic Republic",
    },
    {
      value: 'LV',
      text: '(LV) Latvia',
    },
    {
      value: 'LB',
      text: '(LB) Lebanon',
    },
    {
      value: 'LS',
      text: '(LS) Lesotho',
    },
    {
      value: 'LR',
      text: '(LR) Liberia',
    },
    {
      value: 'LY',
      text: '(LY) Libya',
    },
    {
      value: 'LI',
      text: '(LI) Liechtenstein',
    },
    {
      value: 'LT',
      text: '(LT) Lithuania',
    },
    {
      value: 'LU',
      text: '(LU) Luxembourg',
    },
    {
      value: 'MO',
      text: '(MO) Macao',
    },
    {
      value: 'MK',
      text: '(MK) Macedonia, the Former Yugoslav Republic of',
    },
    {
      value: 'MG',
      text: '(MG) Madagascar',
    },
    {
      value: 'MW',
      text: '(MW) Malawi',
    },
    {
      value: 'MY',
      text: '(MY) Malaysia',
    },
    {
      value: 'MV',
      text: '(MV) Maldives',
    },
    {
      value: 'ML',
      text: '(ML) Mali',
    },
    {
      value: 'MT',
      text: '(MT) Malta',
    },
    {
      value: 'MH',
      text: '(MH) Marshall Islands',
    },
    {
      value: 'MQ',
      text: '(MQ) Martinique',
    },
    {
      value: 'MR',
      text: '(MR) Mauritania',
    },
    {
      value: 'MU',
      text: '(MU) Mauritius',
    },
    {
      value: 'YT',
      text: '(YT) Mayotte',
    },
    {
      value: 'MX',
      text: '(MX) Mexico',
    },
    {
      value: 'FM',
      text: '(FM) Micronesia, Federated States of',
    },
    {
      value: 'MD',
      text: '(MD) Moldova, Republic of',
    },
    {
      value: 'MC',
      text: '(MC) Monaco',
    },
    {
      value: 'MN',
      text: '(MN) Mongolia',
    },
    {
      value: 'ME',
      text: '(ME) Montenegro',
    },
    {
      value: 'MS',
      text: '(MS) Montserrat',
    },
    {
      value: 'MA',
      text: '(MA) Morocco',
    },
    {
      value: 'MZ',
      text: '(MZ) Mozambique',
    },
    {
      value: 'MM',
      text: '(MM) Myanmar',
    },
    {
      value: 'NA',
      text: '(NA) Namibia',
    },
    {
      value: 'NR',
      text: '(NR) Nauru',
    },
    {
      value: 'NP',
      text: '(NP) Nepal',
    },
    {
      value: 'NL',
      text: '(NL) Netherlands',
    },
    {
      value: 'NC',
      text: '(NC) New Caledonia',
    },
    {
      value: 'NZ',
      text: '(NZ) New Zealand',
    },
    {
      value: 'NI',
      text: '(NI) Nicaragua',
    },
    {
      value: 'NE',
      text: '(NE) Niger',
    },
    {
      value: 'NG',
      text: '(NG) Nigeria',
    },
    {
      value: 'NU',
      text: '(NU) Niue',
    },
    {
      value: 'NF',
      text: '(NF) Norfolk Island',
    },
    {
      value: 'MP',
      text: '(MP) Northern Mariana Islands',
    },
    {
      value: 'NO',
      text: '(NO) Norway',
    },
    {
      value: 'OM',
      text: '(OM) Oman',
    },
    {
      value: 'PK',
      text: '(PK) Pakistan',
    },
    {
      value: 'PW',
      text: '(PW) Palau',
    },
    {
      value: 'PS',
      text: '(PS) Palestine, State of',
    },
    {
      value: 'PA',
      text: '(PA) Panama',
    },
    {
      value: 'PG',
      text: '(PG) Papua New Guinea',
    },
    {
      value: 'PY',
      text: '(PY) Paraguay',
    },
    {
      value: 'PE',
      text: '(PE) Peru',
    },
    {
      value: 'PH',
      text: '(PH) Philippines',
    },
    {
      value: 'PN',
      text: '(PN) Pitcairn',
    },
    {
      value: 'PL',
      text: '(PL) Poland',
    },
    {
      value: 'PT',
      text: '(PT) Portugal',
    },
    {
      value: 'PR',
      text: '(PR) Puerto Rico',
    },
    {
      value: 'QA',
      text: '(QA) Qatar',
    },
    {
      value: 'RE',
      text: '(RE) Réunion',
    },
    {
      value: 'RO',
      text: '(RO) Romania',
    },
    {
      value: 'RU',
      text: '(RU) Russian Federation',
    },
    {
      value: 'RW',
      text: '(RW) Rwanda',
    },
    {
      value: 'BL',
      text: '(BL) Saint Barthélemy',
    },
    {
      value: 'SH',
      text: '(SH) Saint Helena, Ascension and Tristan da Cunha',
    },
    {
      value: 'KN',
      text: '(KN) Saint Kitts and Nevis',
    },
    {
      value: 'LC',
      text: '(LC) Saint Lucia',
    },
    {
      value: 'MF',
      text: '(MF) Saint Martin (French part)',
    },
    {
      value: 'PM',
      text: '(PM) Saint Pierre and Miquelon',
    },
    {
      value: 'VC',
      text: '(VC) Saint Vincent and the Grenadines',
    },
    {
      value: 'WS',
      text: '(WS) Samoa',
    },
    {
      value: 'SM',
      text: '(SM) San Marino',
    },
    {
      value: 'ST',
      text: '(ST) Sao Tome and Principe',
    },
    {
      value: 'SA',
      text: '(SA) Saudi Arabia',
    },
    {
      value: 'SN',
      text: '(SN) Senegal',
    },
    {
      value: 'RS',
      text: '(RS) Serbia',
    },
    {
      value: 'SC',
      text: '(SC) Seychelles',
    },
    {
      value: 'SL',
      text: '(SL) Sierra Leone',
    },
    {
      value: 'SG',
      text: '(SG) Singapore',
    },
    {
      value: 'SX',
      text: '(SX) Sint Maarten (Dutch part)',
    },
    {
      value: 'SK',
      text: '(SK) Slovakia',
    },
    {
      value: 'SI',
      text: '(SI) Slovenia',
    },
    {
      value: 'SB',
      text: '(SB) Solomon Islands',
    },
    {
      value: 'SO',
      text: '(SO) Somalia',
    },
    {
      value: 'ZA',
      text: '(ZA) South Africa',
    },
    {
      value: 'GS',
      text: '(GS) South Georgia and the South Sandwich Islands',
    },
    {
      value: 'SS',
      text: '(SS) South Sudan',
    },
    {
      value: 'ES',
      text: '(ES) Spain',
    },
    {
      value: 'LK',
      text: '(LK) Sri Lanka',
    },
    {
      value: 'SD',
      text: '(SD) Sudan',
    },
    {
      value: 'SR',
      text: '(SR) Suriname',
    },
    {
      value: 'SJ',
      text: '(SJ) Svalbard and Jan Mayen',
    },
    {
      value: 'SZ',
      text: '(SZ) Swaziland',
    },
    {
      value: 'SE',
      text: '(SE) Sweden',
    },
    {
      value: 'CH',
      text: '(CH) Switzerland',
    },
    {
      value: 'SY',
      text: '(SY) Syrian Arab Republic',
    },
    {
      value: 'TW',
      text: '(TW) Taiwan, Province of China',
    },
    {
      value: 'TJ',
      text: '(TJ) Tajikistan',
    },
    {
      value: 'TZ',
      text: '(TZ) Tanzania, United Republic of',
    },
    {
      value: 'TH',
      text: '(TH) Thailand',
    },
    {
      value: 'TL',
      text: '(TL) Timor-Leste',
    },
    {
      value: 'TG',
      text: '(TG) Togo',
    },
    {
      value: 'TK',
      text: '(TK) Tokelau',
    },
    {
      value: 'TO',
      text: '(TO) Tonga',
    },
    {
      value: 'TT',
      text: '(TT) Trinidad and Tobago',
    },
    {
      value: 'TN',
      text: '(TN) Tunisia',
    },
    {
      value: 'TR',
      text: '(TR) Turkey',
    },
    {
      value: 'TM',
      text: '(TM) Turkmenistan',
    },
    {
      value: 'TC',
      text: '(TC) Turks and Caicos Islands',
    },
    {
      value: 'TV',
      text: '(TV) Tuvalu',
    },
    {
      value: 'UG',
      text: '(UG) Uganda',
    },
    {
      value: 'UA',
      text: '(UA) Ukraine',
    },
    {
      value: 'AE',
      text: '(AE) United Arab Emirates',
    },
    {
      value: 'GB',
      text: '(GB) United Kingdom',
    },
    {
      value: 'US',
      text: '(US) United States',
    },
    {
      value: 'UM',
      text: '(UM) United States Minor Outlying Islands',
    },
    {
      value: 'UY',
      text: '(UY) Uruguay',
    },
    {
      value: 'UZ',
      text: '(UZ) Uzbekistan',
    },
    {
      value: 'VU',
      text: '(VU) Vanuatu',
    },
    {
      value: 'VE',
      text: '(VE) Venezuela, Bolivarian Republic of',
    },
    {
      value: 'VN',
      text: '(VN) Viet Nam',
    },
    {
      value: 'VG',
      text: '(VG) Virgin Islands, British',
    },
    {
      value: 'VI',
      text: '(VI) Virgin Islands, U.S.',
    },
    {
      value: 'WF',
      text: '(WF) Wallis and Futuna',
    },
    {
      value: 'EH',
      text: '(EH) Western Sahara',
    },
    {
      value: 'YE',
      text: '(YE) Yemen',
    },
    {
      value: 'ZM',
      text: '(ZM) Zambia',
    },
    {
      value: 'ZW',
      text: '(ZW) Zimbabwe',
    },
  ];
}
