















import { Component, Vue } from 'vue-property-decorator';
@Component({
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
export default class MaterialDialog extends Vue {}
