import API from '../repository';

const RESOURCE = '/admins';

export interface AdminLoginImpl {
  admin_email: string;
  admin_password: string;
}

export default {
  async adminLogin(data: AdminLoginImpl): Promise<{ access_token: string }> {
    return await API.post(`${RESOURCE}/login`, {
      user_email: data.admin_email,
      password: data.admin_password,
    });
  },

  async getAdminData(): Promise<any> {
    return await API.get(`${RESOURCE}/me`);
  },
};
