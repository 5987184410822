import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBL0FCZvcXBv3vP2C10yE01FqflhBqst7k',
  authDomain: 'servicio-nota-simple.firebaseapp.com',
  databaseURL: 'https://servicio-nota-simple.firebaseio.com',
  projectId: 'servicio-nota-simple',
  storageBucket: 'servicio-nota-simple.appspot.com',
  messagingSenderId: '247991021229',
  appId: '1:247991021229:web:c2283bf358bab47502a024',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
