import Vue from 'vue';
import { upperFirst, camelCase } from 'lodash';

const requireComponent = require.context('@/components/base', true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig);
});
