





import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CardFilters extends Vue {
  @Prop({ default: 'Filters:' })
  label!: string;
}
