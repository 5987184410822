import Vue from 'vue';
import Vuex from 'vuex';

import { userAuthStore } from './modules/userAuth.store';
import { webConfigStore } from './modules/webConfig.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userAuthStore,
    webConfigStore,
  },
});
