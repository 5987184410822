import { RouteConfig } from 'vue-router';

const DashboardRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "access" */ '../views/Home.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/payments/ListPayments.vue'),
  },
  {
    path: '/payments/:payment_id',
    name: 'PaymentDetails',
    props: true,
    component: () => import(/* webpackChunkName: "payments_details" */ '../views/payments/Payment.vue'),
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "customers" */ '../views/customers/ListCustomers.vue'),
  },
  {
    path: '/customer/:customer_id',
    name: 'CustomerProfile',
    props: true,
    component: () => import(/* webpackChunkName: "customers_profile" */ '../views/customers/CustomerProfile.vue'),
  },
  {
    path: '/requests',
    name: 'ListRequests',
    component: () => import(/* webpackChunkName: "list_requests" */ '../views/requests/ListRequests.vue'),
  },
  {
    path: '/requests/:request_id',
    name: 'Request',
    props: true,
    component: () => import(/* webpackChunkName: "request_requests" */ '../views/requests/baseRequest.vue'),
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import(/* webpackChunkName: "Billing" */ '../views/billing/billing.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products/ListProducts.vue'),
  },
];

export default DashboardRoutes;
