import axios from 'axios';
import store from '@/store';
import UserTypes from '@/store/types/UserTypes';

const Api = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 360000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Request interceptor and inject token bearer
Api.interceptors.request.use(
  async config => {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + store.getters['userAuthStore/' + UserTypes.getters.USERTOKEN],
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);
export default Api;
