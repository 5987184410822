import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { State } from '@/store/interfaces/User';
import UserTypes from '@/store/types/UserTypes';

import adminRepository, { AdminLoginImpl } from '@/repository/modules/admin.repository';

import router from '@/router';

const namespaced = true;

const state: State = {
  user: null,
};

const getters: GetterTree<State, any> = {
  [UserTypes.getters.GETUSER]: state => state.user,
  [UserTypes.getters.USERISLOGGED]: state => {
    if (state.user) return true;
    if (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== 'null') return true;
    return false;
  },
  [UserTypes.getters.USERTOKEN]: () => localStorage.getItem('access_token'),
};

const mutations: MutationTree<State> = {
  [UserTypes.mutations.SETUSER]: (state, user) => (state.user = user),
};

const actions: ActionTree<State, any> = {
  [UserTypes.actions.LOGINADMIN]: async ({ dispatch }, payload: AdminLoginImpl) => {
    const { access_token } = await adminRepository.adminLogin(payload);
    if (access_token) {
      localStorage.setItem('access_token', access_token);
      dispatch(UserTypes.actions.UPDATEADMINDATA);
    }
  },

  [UserTypes.actions.UPDATEADMINDATA]: async ({ commit }) => {
    const result = await adminRepository.getAdminData();
    commit(UserTypes.mutations.SETUSER, result);
  },

  [UserTypes.actions.LOGOUTADMIN]: async ({ commit }) => {
    localStorage.removeItem('access_token');
    commit(UserTypes.mutations.SETUSER, null);
    router.push({ name: 'Access' });
  },
};

export const userAuthStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
